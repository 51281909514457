export function getDailySummary(query) {
    const mockData = {
        status: 200,
        data: [
            {
                book: "B",
                total_open_pnl_at_eod: -102.47,
                total_close_pnl_at_eod: 19611.72,
                total_net_pnl_at_eod: 19509.25,
            },
            {
                book: "S",
                total_open_pnl_at_eod: -1138.61,
                total_close_pnl_at_eod: 10425.22,
                total_net_pnl_at_eod: 9286.61,
            },
            {
                book: "Test",
                total_open_pnl_at_eod: -2240.31,
                total_close_pnl_at_eod: 16122.41,
                total_net_pnl_at_eod: 13882.1,
            },
            {
                book: "B2",
                total_open_pnl_at_eod: -271.30,
                total_close_pnl_at_eod: 422.41,
                total_net_pnl_at_eod: 151.01,
            },
        ],
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

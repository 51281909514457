
export function getMTDVolume(query) {
    const mockData = {
        status: 200,
        data: [
            {
                "book": "B",
                "mtd_vol": 50615811.535402514
            },
            {
                "book": "S",
                "mtd_vol": 4513998.687315751
            },
            {
                "book": "B2",
                "mtd_vol": 9615811.71
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

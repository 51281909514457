<template>
    <div class="home d-flex" v-resize="onResize">
        <v-card class="d-flex flex-grow-1">
            <v-container fluid class="d-flex flex-grow-1">
                <v-row dense class="d-flex flex-grow-1" style="width: 100%">
                    <v-col lg="8" md="8" xs="12" cols="12">
                        <v-card
                            class="d-flex flex-column"
                            style="height: 100%"
                            outlined
                            id="monitorHomeTutorial"
                        >
                            <v-card-title
                                style="border-bottom: 1px solid #404040"
                                class="pt-1"
                            >
                                <div
                                    class="text-left"
                                    style="
                                        margin-left: 0;
                                        width: 50%;
                                        font-size: 13px;
                                        font-weight: normal;
                                    "
                                >
                                    MONITOR
                                </div>
                                <div
                                    class="text-right"
                                    style="margin-right: 0; width: 50%"
                                >
                                    <span id="monitorFunctions">
                                        <template
                                            v-if="
                                                !VUETIFY_BREAKPOINT.mobile &&
                                                allowDownloadAll
                                            "
                                        >
                                            <v-btn
                                                small
                                                dark
                                                color="primary"
                                                dense
                                                class="mr-1"
                                                :loading="
                                                    allBookDownloadLoading
                                                "
                                                @click="downloadAllBooks"
                                                >Download All</v-btn
                                            >
                                        </template>
                                        <template
                                            v-if="!VUETIFY_BREAKPOINT.mobile"
                                        >
                                            <v-icon
                                                color="green"
                                                dark
                                                dense
                                                @click="startDownload"
                                                >mdi-microsoft-excel</v-icon
                                            ><download-excel
                                                id="csvExport"
                                                :fetch="fetchData"
                                                :fields="computedCSVFields"
                                                type="csv"
                                                style="display: none"
                                                :name="downloadExcelName"
                                                :escapeCsv="false"
                                            >
                                            </download-excel>
                                        </template>
                                        <v-menu offset-y dense>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-btn
                                                    small
                                                    color="default"
                                                    dark
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="ml-1"
                                                >
                                                    {{
                                                        detailIntervalOptions.find(
                                                            (option) =>
                                                                option.value ===
                                                                selectedDetailInterval
                                                        ).key
                                                    }}
                                                </v-btn>
                                            </template>
                                            <v-list dense>
                                                <v-list-item
                                                    v-for="item in detailIntervalOptions"
                                                    :key="item.key"
                                                    @click="
                                                        () => {
                                                            selectedDetailInterval =
                                                                item.value;
                                                        }
                                                    "
                                                >
                                                    <v-list-item-title>{{
                                                        item.key
                                                    }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </span>
                                </div>
                            </v-card-title>

                            <v-row
                                class="flex-shrink-0 flex-grow-0"
                                align="center"
                                style="margin: 0"
                            >
                                <v-col
                                    :cols="briefCol"
                                    class="pl-6"
                                    style="padding: 0"
                                >
                                    <DanMenu
                                        :name="selectedBookName"
                                        :menu-items="books"
                                        @dan-menu-click="onMenuItemClick"
                                    />
                                </v-col>
                                <v-col
                                    :cols="briefCol"
                                    align="center"
                                    style="padding: 0"
                                >
                                    <CardHead
                                        :name="'Unrealized'"
                                        :value="mainBookTitle.unrealized"
                                        :description="
                                            mainBookTitleDesc.unrealized
                                        "
                                    />
                                </v-col>
                                <v-col
                                    :cols="briefCol"
                                    align="center"
                                    style="padding: 0"
                                >
                                    <CardHead
                                        :name="'Realized'"
                                        :value="mainBookTitle.realized"
                                        :description="
                                            mainBookTitleDesc.realized
                                        "
                                    />
                                </v-col>
                                <v-col
                                    :cols="briefCol"
                                    align="center"
                                    style="padding: 0"
                                >
                                    <CardHead
                                        :name="'Daily Net'"
                                        :value="mainBookTitle.dailyNet"
                                        :description="
                                            mainBookTitleDesc.dailyNet
                                        "
                                    />
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>

                            <!-- PK主表逻辑 -->
                            <v-data-table
                                dense
                                :mobile-breakpoint="0"
                                :headers="mainHeader"
                                :items="computedPKData"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :header-props="{ sortIcon: null }"
                                :custom-filter="filterTable"
                                :customSort="customSort"
                                item-key="symbol"
                                @click:row="rowClicked"
                                class="elevation-0 flex-grow-1"
                                :items-per-page="30"
                                :footer-props="{
                                    itemsPerPageOptions: [10, 20, 30],
                                    showFirstLastPage: false,
                                    'items-per-page-text': '',
                                }"
                                :class="{
                                    'fix-first-col': VUETIFY_BREAKPOINT.mobile,
                                }"
                                id="home-table"
                                :height="tableHeight"
                                fixed-header
                                v-sortable-table="{
                                    onEnd: sortPKHeadersAndUpdateTheKey,
                                }"
                                :key="anIncreasingNumberForMain"
                            >
                                <template v-slot:header.symbol="{ header }">
                                    {{ header.text }}
                                    <v-menu
                                        offset-y
                                        :close-on-content-click="false"
                                        top
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                small
                                                v-bind="attrs"
                                                v-on="on"
                                                @click.prevent="
                                                    onSearch('symbolSearch')
                                                "
                                                id="symbolSearch"
                                            >
                                                mdi-magnify
                                            </v-icon>
                                        </template>
                                        <div
                                            style="
                                                background-color: #1e1e1e;
                                                width: 280px;
                                            "
                                        >
                                            <v-text-field
                                                v-model="search"
                                                class="px-4"
                                                type="text"
                                                ref="symbolSearch"
                                                label="Enter the search term"
                                                style="width: 100%"
                                                single-line
                                                hide-details
                                            ></v-text-field>
                                            <v-btn
                                                @click="search = ''"
                                                small
                                                text
                                                color="primary"
                                                class="ml-2 mb-2"
                                                style="padding: 6px"
                                            >
                                                Clear
                                            </v-btn>
                                        </div>
                                    </v-menu>
                                </template>
                                <template v-slot:header.net="{ header }">
                                    <v-tooltip top open-delay="500">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <span v-bind="attrs" v-on="on">{{
                                                header.text
                                            }}</span>
                                        </template>
                                        <span
                                            >Net Volume = Long + Short (Sort by
                                            absolute value)</span
                                        >
                                    </v-tooltip>
                                </template>
                                <!-- PK表鼠标点击后的副表 -->
                                <template v-slot:item.symbol="{ item }">
                                    <v-tooltip right open-delay="500">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <a
                                                v-bind="attrs"
                                                v-on="on"
                                                @click.stop="
                                                    () => {
                                                        getEODSymbolMaps(
                                                            item.symbol
                                                        );
                                                    }
                                                "
                                                style="
                                                    background: transparent;
                                                    padding: 0px;
                                                    color: white;
                                                "
                                                id="symbolEODDialog"
                                            >
                                                {{ item.symbol }}</a
                                            >
                                        </template>
                                        <TooltipTable :monitor-item="item" />
                                    </v-tooltip>
                                </template>
                                <template v-slot:item.net="{ item }">
                                    {{ item.netStr }}
                                </template>
                                <template v-slot:item.dailyNet="{ item }">
                                    {{ item.dailyNetStr }}
                                </template>
                                <template v-slot:item.longPositions="{ item }">
                                    {{ item.longPositionsStr }}
                                </template>
                                <template v-slot:item.shortPositions="{ item }">
                                    {{ item.shortPositionsStr }}
                                </template>
                                <template
                                    v-slot:item.floatingProfitEOD="{ item }"
                                >
                                    <div v-bind:class="`${item.color}`">
                                        {{ item.floatingProfitEODStr }}
                                    </div>
                                </template>
                                <template
                                    v-slot:item.closedProfitEOD="{ item }"
                                >
                                    <a
                                        @click.stop="
                                            () => {
                                                getPNLSummary(item);
                                            }
                                        "
                                        style="
                                            background: transparent;
                                            padding: 0px;
                                            color: white;
                                        "
                                    >
                                        {{ item.closedProfitEODStr }}</a
                                    >
                                </template>
                                <template v-slot:item.closedProfit="{ item }">
                                    {{ item.closedProfitStr }}
                                </template>
                                <template v-slot:item.floatingProfit="{ item }">
                                    {{ item.floatingProfitStr }}
                                </template>
                                <template v-slot:item.symbolExposure="{ item }">
                                    {{ item.symbolExposureStr }}
                                </template>
                                <template
                                    v-slot:item.weightedAvgLongPrice="{ item }"
                                >
                                    {{ item.weightedAvgLongPriceStr }}
                                </template>
                                <template
                                    v-slot:item.weightedAvgShortPrice="{ item }"
                                >
                                    {{ item.weightedAvgShortPriceStr }}
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-col>
                    <v-col lg="4" md="4" xs="12" cols="12" class="d-flex">
                        <div class="d-flex flex-column flex-grow-1">
                            <AllBooks
                                ref="allBooks"
                                :mtdPnl="mtdPnl"
                                :mtdVolume="mtdVolume"
                                @changeBook="changeSelectedBook"
                                :tableHeight="allBookTableHeight"
                                id="allBookHomeTutorial"
                            />
                            <ExposureChart
                                class="mt-2"
                                ref="exposureChart"
                                style="margin-top: 10px"
                                :exposureData="exposureData"
                                :chartHeight="exposureChartHeight"
                                id="exposureHomeTutorial"
                            />
                        </div>
                    </v-col>
                </v-row>

                <!-- PK monitor table modal when click realized number -->
                <v-dialog
                    v-model="dialogCloseSummaryVisible"
                    :width="dialogWidth"
                    :hide-overlay="true"
                    :persistent="true"
                    :fullscreen="fullscreen"
                >
                    <v-card>
                        <v-app-bar
                            flat
                            color="rgba(0, 0, 0, 0)"
                            style="background: rgb(26, 57, 88)"
                        >
                            <v-toolbar-title class="text-h6 white--text pl-0"
                                >Close Summary - {{ closeSummarySymbol }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="white"
                                icon
                                @click="dialogCloseSummaryVisible = false"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-app-bar>

                        <v-container>
                            <v-row>
                                <v-col cols="12" class="pt-0 pb-0 pl-0">
                                    <v-tabs>
                                        <v-tab
                                            dense
                                            v-on:click="changeTab('login')"
                                            >PNL by Login</v-tab
                                        >
                                        <v-tab
                                            dense
                                            v-on:click="changeTab('symbol')"
                                            >PNL by Symbol</v-tab
                                        >

                                        <!-- <v-tab v-on:click="changeTab('raw')"
                                            >Raw Order</v-tab
                                        > -->
                                    </v-tabs>
                                </v-col>
                            </v-row>
                            <v-row v-if="selectedKey === 'login'" class="mt-5">
                                <v-col cols="12" class="pt-0 pb-0">
                                    <v-data-table
                                        :headers="closeSummaryLoginHeaders"
                                        :items="closeSummaryLoginData"
                                        dense
                                        :header-props="{ sortIcon: null }"
                                        :search="searchCloseSummaryLogin"
                                        :items-per-page="15"
                                        :loading="loading"
                                        :mobile-breakpoint="0"
                                        :footer-props="{
                                            'items-per-page-text': '',
                                        }"
                                        :class="{
                                            'fix-first-col':
                                                VUETIFY_BREAKPOINT.mobile,
                                        }"
                                    >
                                        <template
                                            v-slot:header.login="{ header }"
                                        >
                                            {{ header.text }}
                                            <v-menu
                                                offset-y
                                                :close-on-content-click="false"
                                                top
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-icon
                                                        small
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @click.prevent="
                                                            onSearch(
                                                                'loginSearch'
                                                            )
                                                        "
                                                    >
                                                        mdi-magnify
                                                    </v-icon>
                                                </template>
                                                <div
                                                    style="
                                                        background-color: #1e1e1e;
                                                        width: 280px;
                                                    "
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            searchCloseSummaryLogin
                                                        "
                                                        class="px-4"
                                                        type="text"
                                                        ref="loginSearch"
                                                        label="Enter the search term"
                                                        style="width: 100%"
                                                        single-line
                                                        hide-details
                                                    ></v-text-field>
                                                    <v-btn
                                                        @click="
                                                            searchCloseSummaryLogin =
                                                                ''
                                                        "
                                                        small
                                                        text
                                                        color="primary"
                                                        class="ml-2 mb-2"
                                                        style="padding: 6px"
                                                    >
                                                        Clear
                                                    </v-btn>
                                                </div>
                                            </v-menu>
                                        </template>
                                        <template v-slot:item.profit="{ item }">
                                            {{
                                                numberWithCommas(
                                                    item.profit.toFixed(2)
                                                )
                                            }}
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <v-row v-if="selectedKey === 'symbol'" class="mt-5">
                                <v-col cols="12" class="pt-0 pb-0">
                                    <v-data-table
                                        :headers="closeSummarySymbolHeaders"
                                        :items="closeSummarySymbolData"
                                        dense
                                        :header-props="{ sortIcon: null }"
                                        :items-per-page="10"
                                        :loading="loading"
                                        :mobile-breakpoint="0"
                                        :footer-props="{
                                            'items-per-page-text': '',
                                        }"
                                        :class="{
                                            'fix-first-col':
                                                VUETIFY_BREAKPOINT.mobile,
                                        }"
                                    >
                                        <template v-slot:item.profit="{ item }">
                                            {{
                                                numberWithCommas(
                                                    item.profit.toFixed(2)
                                                )
                                            }}
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <!-- <v-row v-if="selectedKey === 'raw'" class="mt-5">
                                <v-col cols="12" class="pt-0 pb-0">
                                    <v-text-field
                                        v-model="searchCloseSummaryRaw"
                                        append-icon="mdi-magnify"
                                        label="Search"
                                        dense
                                        single-line
                                        hide-details
                                    />
                                    <v-data-table
                                        :headers="closeSummaryRawHeaders"
                                        :items="closeSummaryRawData"
                                        :search="searchCloseSummaryRaw"
                                        dense
                                        :loading="loading"
                                    >
                                    </v-data-table>
                                </v-col>
                            </v-row> -->
                        </v-container>
                    </v-card>
                </v-dialog>

                <!-- EOD Symbol map -->
                <v-dialog
                    v-model="symbolMap"
                    :width="800"
                    :hide-overlay="true"
                    :persistent="true"
                    :fullscreen="fullscreen"
                >
                    <v-card>
                        <v-app-bar
                            flat
                            color="rgba(0, 0, 0, 0)"
                            style="background: rgb(26, 57, 88)"
                        >
                            <v-toolbar-title class="text-h6 white--text pl-0"
                                >EOD Symbol Price Map - {{ selectedSymbolMap }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="white"
                                icon
                                @click="symbolMap = false"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-app-bar>

                        <v-container>
                            <v-row>
                                <v-col cols="12" class="pt-0 pb-0">
                                    <v-data-table
                                        :headers="symbolMapHeader"
                                        :items="
                                            !!selectedSymbolMap
                                                ? computedSymbolPriceMapData[
                                                      selectedSymbolMap
                                                  ]
                                                : []
                                        "
                                        dense
                                        :header-props="{ sortIcon: null }"
                                        :items-per-page="15"
                                        :mobile-breakpoint="0"
                                        :footer-props="{
                                            'items-per-page-text': '',
                                        }"
                                        :class="{
                                            'fix-first-col':
                                                VUETIFY_BREAKPOINT.mobile,
                                        }"
                                    >
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-dialog>

                <LoginSummaryDialog
                    v-if="loginSummaryHistoryVisible"
                    :fullLogin="fullLoginValue"
                    :login="loginValue"
                    :server="serverValue"
                    :symbol="symbolValue"
                    :book="selectedBook"
                />
            </v-container>
        </v-card>
        <v-dialog v-model="csvFieldsDialog" width="1000" :persistent="true">
            <v-card>
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">
                        CSV Table Fields
                    </v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-btn color="white" icon @click="cancelDownload">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>

                <v-card-actions>
                    <v-row>
                        <v-col cols="6" style="display: flex">
                            <v-text-field
                                v-model="inputCsvName"
                                label="CSV export name"
                                :rules="inputRules"
                                hide-details
                                required
                            ></v-text-field>

                            <b
                                style="
                                    display: flex;
                                    align-items: end;
                                    height: 100%;
                                "
                                >.csv</b
                            >
                        </v-col>
                        <v-col cols="6">
                            <v-switch
                                v-model="selectAll"
                                label="Select All"
                                hide-details
                                @click="toggleSwitch"
                            >
                            </v-switch>
                        </v-col>
                        <v-col
                            cols="3"
                            v-for="(item, idx) in Object.keys(
                                selectedCSVFields
                            )"
                            :key="`CSV-${idx}`"
                        >
                            <v-checkbox
                                v-model="selectedCSVFields[item]"
                                :label="item"
                                hide-details
                                @click="checkAll"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="cancelDownload">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" text @click="confirmDownload">
                        Download
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <SymbolDetailDialog
            :visible.sync="monitorDialog"
            :symbol="currentExpanded"
            :symbolDetailArray="symbolDetailArray"
            :table-loading="monitorDialogLoading"
            :showHomeLoginDetailSummary="showHomeLoginDetailSummary"
            @login-click="loadLoginSummaryHistoryDialog"
            :selectedBook="selectedBook"
        />
        <a id="downloadAnchorElement" style="display: none"></a>
        <a id="homeSymbolBriefDownload" style="display: none"></a>
    </div>
</template>
<script>
import { getMTDPnl } from "@services/statistic/mtd-pnl";
import { getMTDVolume } from "@services/statistic/mtd-volume";
import dayjs from "dayjs";
import md5 from "md5";
import { helper } from "@components/mixins/helper";
import { permission } from "@components/mixins/permission";
import { mapActions, mapMutations, mapState } from "vuex";
import CardHead from "./home/ComponentCardHead";
import ExposureChart from "./home/ComponentExposureChart";
import common from "@assets/js/common";
import LoginSummaryDialog from "@components/login-summary/ComponentLoginSummaryDialog";
import { position } from "@components/mixins/position";
import { snackbar } from "@components/mixins/snackbar";
import deepClone from "deep-clone";
import AllBooks from "./home/ComponentAllBooks";
import DanMenu from "./home/ComponentDanMenu";
import SymbolDetailDialog from "./home/ComponentSymbolDetailDialog";
import TooltipTable from "./home/ComponentTooltipTable";
import introJs from "intro.js";
import { TutorialConstant } from "@assets/tutorialConstant";
import { putAuthUser } from "@services/account/auth";
import { getToken } from "@utils/auth";
import mockedData from "./home/mockedData.js";

export default {
    name: "Home",
    components: {
        ExposureChart,
        LoginSummaryDialog,
        CardHead,
        DanMenu,
        AllBooks,
        SymbolDetailDialog,
        TooltipTable,
    },
    mixins: [helper, position, permission, snackbar],
    data() {
        return {
            mockedAllBookInterval: null,
            chartInterval: null,
            mockedHeaderTileInterval: null,
            mockedBookDetailInterval: null,
            mockedSymbolDetailInterval: null,

            allBookDownloadLoading: false,
            startTiming: 1000,
            allowDownloadAll: false,
            tutorialConstant: TutorialConstant,
            inputRules: [
                (v) =>
                    !!v ||
                    "Please enter a new value as the name of CSV output.",
            ],
            symbolMap: false,
            csvFieldsDialog: false,
            selectedCSVFields: [],
            computedCSVFields: {},
            inputCsvName: "",
            selectAll: true,
            loginSummaryHistoryVisible: false,
            pkSocket: null,
            lockReconnect: false, // 避免 socket 重复连接
            selectedBook: "",
            selectedSymbolMap: null,
            sortBy: "symbolExposure",
            sortDesc: true,
            fullscreen: false,
            symbolMapHeader: [
                {
                    text: "Symbol",
                    align: "start",
                    sortable: false,
                    value: "symbolName",
                },
                {
                    text: "Ask",
                    value: "ask",
                    align: "end",
                    sortable: true,
                },
                {
                    text: "Bid",
                    value: "bid",
                    align: "end",
                    sortable: true,
                },
                {
                    text: "Date",
                    value: "dateTime",
                    align: "end",
                    sortable: true,
                },
            ],
            pkData: [],
            loadSubTable: false,
            search: "",
            currentExpanded: "",
            monitorDialogLoading: false,
            symbolDetailOriginHash: "",
            symbolDetailArray: [],
            showHomeLoginDetailSummary: false,
            dialogCloseSummaryVisible: false,
            closeSummaryLoginData: [],
            closeSummaryLoginHeaders: [
                {
                    text: "Login",
                    align: "start",
                    sortable: false,
                    value: "login",
                },
                {
                    text: "Realized(EOD)",
                    value: "realized_eod",
                    align: "end",
                    sortable: true,
                },
                {
                    text: "Realized(MT)",
                    value: "realized_mt4",
                    align: "end",
                    sortable: true,
                },
            ],
            searchCloseSummaryLogin: "",
            closeSummarySymbolData: [],
            closeSummarySymbolHeaders: [
                {
                    text: "Symbol",
                    align: "start",
                    sortable: false,
                    value: "symbol",
                },
                {
                    text: "Realized(EOD)",
                    value: "realized_eod",
                    align: "end",
                    sortable: true,
                },
                {
                    text: "Realized(MT)",
                    value: "realized_mt4",
                    align: "end",
                    sortable: true,
                },
            ],
            searchCloseSummarySymbol: "",
            closeSummaryRawData: [],
            closeSummaryRawHeaders: [
                {
                    text: "Login",
                    align: "start",
                    sortable: false,
                    value: "login",
                },
                { text: "Type", value: "type", sortable: true },
                { text: "Ticket", value: "ticket" },
                { text: "Profit", value: "profit", sortable: true },
                { text: "Open Time", value: "open_time", sortable: true },
                { text: "Close Time", value: "close_time", sortable: true },
                { text: "Open Price", value: "open_price" },
                { text: "Close Price", value: "close_price" },
                { text: "Comment", value: "comment" },
                { text: "Reason", value: "tradeReason" },
                { text: "Symbol", value: "symbol" },
                { text: "Volume", value: "volume" },
                { text: "Book", value: "book" },
            ],
            searchCloseSummaryRaw: "",
            selectedKey: "login",
            dialogWidth: "450px",
            closeSummarySymbol: "",
            loadLoginHistoryChart: 0,
            fullLoginValue: "",
            loginValue: "",
            serverValue: "",
            symbolValue: "",
            bookList: [],
            isDataStreaming: null,
            mtdVolume: [],
            mtdPnl: [],
            tableHeight: 616,
            allBookTableHeight: 200,
            exposureChartHeight: 400,
            anIncreasingNumberForMain: 1,
            anIncreasingNumberForSub: 1,
            mainHeader: null,
            allowSymbolDetailsDownload: false,
            isCsvRecording: false,
            symbolDetails: null,
            allBookHBInterval: null,
            detailHBInterval: null,
            selectedDetailInterval: 1000,
            detailIntervalOptions: [
                { key: "0.3s", value: 300 },
                { key: "0.5s", value: 500 },
                { key: "1s", value: 1000 },
                { key: "5s", value: 5000 },
                { key: "10s", value: 10000 },
                { key: "push", value: -1 },
            ],
            exposureData: null,
            monitorDialog: false,
            mockedFPnl: null,
            mockedCPnl: null,
            mockedAllSymbolDetail: mockedData.mockedSymbolDetail,
        };
    },
    computed: {
        ...mapState("Home", [
            "books",
            "pkHeaders",
            "socketUrl",
            "mainBookTitleDesc",
            "csvFields",
            "isSocketConnected",
            "loading",
            "PNLSummaryData",
            "selectedProfile",
            "allProfile",
            "allBookOptions",
            "symbolDP",
            "selectedInterval",
            "homeTutorial",
            "mainBookTitle",
        ]),

        downloadExcelName() {
            return `${this.inputCsvName.replaceAll(" ", "_")}.csv`;
        },
        selectedBookName() {
            return `${this.selectedBook} BOOK`;
        },
        computedPKData() {
            if (this.search) {
                const computed = this.pkData.filter((item) =>
                    item.symbol
                        .toUpperCase()
                        .includes(this.search.toUpperCase())
                );
                // console.log(computed);
                return computed;
            }
            return this.pkData;
        },
        computedSymbolPriceMapData() {
            const symbolMap = {};
            this.pkData.map((item) => {
                symbolMap[item.symbol] = item._eodPriceList.map((price) => {
                    price.ask = parseFloat(price.ask).toFixed(item.digits);
                    price.bid = parseFloat(price.bid).toFixed(item.digits);
                    return price;
                });
            });
            return symbolMap;
        },
        briefCol() {
            return this.VUETIFY_BREAKPOINT.mobile ? 6 : 3;
        },
    },
    watch: {
        "VUETIFY_BREAKPOINT.mobile": {
            immediate: true,
            handler(val) {
                if (val) {
                    this.fullscreen = true;
                }
            },
        },
        PNLSummaryData(nv) {
            if (nv.length == 0) {
                this.closeSummaryLoginData = [];
                this.closeSummaryRawData = [];
                this.closeSummarySymbolData = [];
            } else {
                if (this.selectedKey == "login") {
                    this.closeSummaryLoginData = nv;
                } else if (this.selectedKey == "symbol") {
                    this.closeSummarySymbolData = nv;
                } else if (this.selectedKey == "raw") {
                    this.closeSummaryRawData = nv;
                } else {
                }
            }
        },
        /**
         * Watch on selectedBook changes
         * @param  {[type]} new value [description]
         * @param  {[type]} old value [description]
         * @return {[type]}    [description]
         */
        selectedBook(nv, ov) {
            this.currentExpanded = null;
            clearInterval(this.detailHBInterval);
            if (nv !== null) {
                // this.snackBarInfo("Connecting to socket...", 5000);

                // reset all table related data
                // this.isDataStreaming = null;
                // this.exposureData = {};
                // this.pkData = [];
                // this.mainBookTitle = {
                //     unrealized: 0,
                //     realized: 0,
                //     dailyNet: 0,
                // };
                this.searchLogin = "";
                this.isDataStreaming = null;
                this.UPDATE_EXPOSURE_DATA({});
                this.pkData = mockedData.mockedPkData[nv];
                this.UPDATE_MAIN_BOOK_TITLE({
                    unrealized: 0,
                    realized: 0,
                    dailyNet: 0,
                });

                // if (this.pkSocket.readyState == 1) {
                //     const bookMain = {
                //         externalMsgType: "CMD_SUBSCRIBE_BOOKS",
                //         books: [this.selectedBook],
                //     };
                //     this.pkSocket.send(JSON.stringify(bookMain));
                // }
            }
        },
        /**
         * Monitor books in store
         * @param  {[type]} nv [description]
         * @param  {[type]} ov [description]
         * @return {[type]}    [description]
         */
        books(nv, ov) {
            if (nv.length !== 0) {
                // this.selectedBook = nv[0].book_name;
                const filterBook = nv.filter((item) => item.book_name === "B");

                if (filterBook.length !== 0) {
                    this.selectedBook = filterBook[0].book_name;
                } else {
                    this.selectedBook = nv[0].book_name;
                }

                this.bookList = [];
                nv.forEach((item) => {
                    this.bookList.push(item.book_name);
                    if (item.children) {
                        item.children.forEach((detail) => {
                            this.bookList.push(detail.book_name);
                        });
                    }
                });

                // load mtd summary
                this.loadMTDVolume();
                this.loadMTDPnl();

                // this.createPKWebSocket();
            }
        },
        // 监听close summary对话框是否打开，关闭时清空图表数据
        dialogCloseSummaryVisible(nv, ov) {
            if (!nv) {
                self = this;
                self.closeSummaryLoginData = [];
                self.closeSummarySymbolData = [];
                self.closeSummaryRawData = [];
                self.searchCloseSummaryLogin = "";
                self.searchCloseSummarySymbol = "";
                self.searchCloseSummaryRaw = "";
            }
        },
        selectedProfile(nv) {
            this.selectedBook = null;

            this.UPDATE_BTN_STATUS(true);
            const remaining = this.allBookOptions.filter((item) => {
                return !nv.books.includes(item.book_name);
            });
            this.UPDATE_BOOKS(remaining);
            if (this.pkSocket) {
                this.pkSocket.close();
                this.initConnection();
            }
        },
        isDataStreaming(nv) {
            if (nv === true) {
                this.UPDATE_BTN_STATUS(false);
                this.snackBarSuccess("Connection Established");
            }
        },
        selectedInterval(nv, ov) {
            clearInterval(this.allBookHBInterval);
            if (ov > 0) {
                if (nv < 0) {
                    const bookBrief = {
                        externalMsgType: "CMD_SUBSCRIBE_BOOKS_BRIEF",
                        books: this.bookList,
                    };
                    this.pkSocket.send(JSON.stringify(bookBrief));

                    if (this.selectedDetailInterval > 0) {
                        this.selectedDetailInterval = -1;
                    }
                }
            } else {
                if (nv > 0) {
                    const bookBrief = {
                        externalMsgType: "CMD_SUBSCRIBE_BOOKS_BRIEF",
                        books: this.bookList,
                    };
                    this.pkSocket.send(JSON.stringify(bookBrief));

                    if (this.selectedInterval < 0) {
                        this.UPDATE_SELECTED_INTERVAL(1000);
                    }
                    if (this.selectedDetailInterval < 0) {
                        this.selectedDetailInterval = 1000;
                    }
                }
            }
        },
        selectedDetailInterval(nv, ov) {
            clearInterval(this.detailHBInterval);
            if (ov > 0) {
                if (nv < 0) {
                    const bookMain = {
                        externalMsgType: "CMD_SUBSCRIBE_BOOKS",
                        books: [this.selectedBook],
                    };
                    this.pkSocket.send(JSON.stringify(bookMain));
                    if (this.selectedInterval > 0) {
                        this.UPDATE_SELECTED_INTERVAL(-1);
                    }
                }
            } else {
                if (nv > 0) {
                    const bookMain = {
                        externalMsgType: "CMD_SUBSCRIBE_BOOKS",
                        books: [this.selectedBook],
                    };
                    this.pkSocket.send(JSON.stringify(bookMain));
                    if (this.selectedInterval < 0) {
                        this.UPDATE_SELECTED_INTERVAL(1000);
                    }
                }
            }
        },
        homeTutorial(nv) {
            if (!!nv) {
                this.getTutorial()
                    .oncomplete(() => this.updateHomeTutorialAction(false))
                    .onexit(() => this.updateHomeTutorialAction(false))
                    .start();
            }
        },
    },
    mounted() {
        this.setHeights();

        //mock data for ccy exposure
        setTimeout(() => {
            this.chartInterval = setInterval(() => {
                this.UPDATE_EXPOSURE_DATA({
                    AUD: 109333.25,
                    BTC: 66434.843,
                    CAD: -125797.7941,
                    EUR: -99539.72,
                    GBP: 125797.7941,
                    JP225: -22087.71506,
                    JPY: 922087.7151,
                    USD: -1509829.173,
                    WTI: -81050,
                    XAU: 614650.8,
                });
            }, 1000);
        }, this.startTiming);

        setTimeout(() => {
            this.mockedSymbolDetailInterval = setInterval(() => {
                this.mockPkSymbolDataChanges();
            }, 1000);
        }, this.startTiming);
    },
    methods: {
        ...mapActions("Home", [
            "getBooksPkAction",
            "calculateAllBooks",
            "getProfileAction",
            "getPNLSummaryAction",
            "updateHomeTutorialAction",
        ]),
        ...mapActions("SettingsServer", ["getServerAction"]),
        ...mapMutations("Home", [
            "UPDATE_SOCKET_CONNECTION_STATUS",
            "UPDATE_ALL_PROFILE",
            "UPDATE_BTN_STATUS",
            "UPDATE_BOOKS",
            "UPDATE_SELECTED_PROFILE",
            "UPDATE_SELECTED_INTERVAL",
            "UPDATE_SYMBOL_SNAPSHOT",
            "UPDATE_LOGIN_SNAPSHOT",
            "UPDATE_MAIN_BOOK_TITLE",
            "UPDATE_EXPOSURE_DATA",
        ]),
        mockPkSymbolDataChanges() {
            for (const symbol of Object.keys(this.mockedAllSymbolDetail)) {
                this.mockedAllSymbolDetail[symbol].map((item) => {
                    const randomNumber =
                        Math.random() * (item.floatingProfitEOD / 10) -
                        item.floatingProfitEOD / 20;
                    const variation =
                        Math.abs(randomNumber) > item.floatingProfitEOD / 100
                            ? randomNumber
                            : 0;
                    item.floatingProfitEOD += variation;
                    item.floatingProfit += variation;

                    const parts = item.login.split(":");
                    const last2 = parts[2].slice(-2);
                    item.login = parts[0] + ":" + parts[1] + ":xxxxx" + last2;
                });
            }

            const clone = deepClone(mockedData.mockedPkData);
            for (const symbol of Object.keys(this.mockedAllSymbolDetail)) {
                const closedProfit = this.mockedAllSymbolDetail[symbol]
                    .map((obj) => obj.closedProfit)
                    .reduce((accumulator, current) => accumulator + current, 0);
                const closedProfitEOD = this.mockedAllSymbolDetail[symbol]
                    .map((obj) => obj.closedProfitEOD)
                    .reduce((accumulator, current) => accumulator + current, 0);
                const floatingProfit = this.mockedAllSymbolDetail[symbol]
                    .map((obj) => obj.floatingProfit)
                    .reduce((accumulator, current) => accumulator + current, 0);
                const floatingProfitEOD = this.mockedAllSymbolDetail[symbol]
                    .map((obj) => obj.floatingProfitEOD)
                    .reduce((accumulator, current) => accumulator + current, 0);
                const longPositions = this.mockedAllSymbolDetail[symbol]
                    .map((obj) => obj.longPositions)
                    .reduce((accumulator, current) => accumulator + current, 0);
                const netPositions = this.mockedAllSymbolDetail[symbol]
                    .map((obj) => obj.netPositions)
                    .reduce((accumulator, current) => accumulator + current, 0);
                const shortPositions = this.mockedAllSymbolDetail[symbol]
                    .map((obj) => obj.shortPositions)
                    .reduce((accumulator, current) => accumulator + current, 0);
                var longCount = 0;
                const weightedAvgLongPriceSum = this.mockedAllSymbolDetail[
                    symbol
                ]
                    .map((obj) => obj.weightedAvgLongPrice)
                    .reduce((accumulator, current) => {
                        if (current !== 0) {
                            longCount += 1;
                        }
                        return accumulator + current;
                    }, 0);

                var shortCount = 0;
                const weightedAvgShortPriceSum = this.mockedAllSymbolDetail[
                    symbol
                ]
                    .map((obj) => obj.weightedAvgShortPrice)
                    .reduce((accumulator, current) => {
                        if (current !== 0) {
                            shortCount += 1;
                        }
                        return accumulator + current;
                    }, 0);
                const weightedAvgLongPrice =
                    longCount === 0 ? 0 : weightedAvgLongPriceSum / longCount;
                const weightedAvgShortPrice =
                    shortCount === 0
                        ? 0
                        : weightedAvgShortPriceSum / shortCount;
                for (const book in clone) {
                    clone[book].map((obj) => {
                        if (obj.symbol === symbol) {
                            const diff =
                                this.pkData.find(
                                    (item) => item.symbol === obj.symbol
                                )?.floatingProfitEODStr - floatingProfitEOD;

                            obj.closedProfitStr = closedProfit.toFixed(2);
                            obj.closedProfitEODStr = closedProfitEOD.toFixed(2);
                            obj.floatingProfitStr = floatingProfit.toFixed(2);
                            obj.floatingProfitEODStr =
                                floatingProfitEOD.toFixed(2);
                            obj.color =
                                diff > 0.01
                                    ? "red"
                                    : diff < -0.01
                                    ? "green"
                                    : "";

                            obj.longPositionsStr = longPositions.toFixed(2);
                            obj.netStr = netPositions.toFixed(2);
                            obj.shortPositionsStr = (-shortPositions).toFixed(
                                2
                            );
                            obj.weightedAvgLongPriceStr =
                                weightedAvgLongPrice.toFixed(2);
                            obj.weightedAvgShortPriceStr =
                                weightedAvgShortPrice.toFixed(2);
                            obj.dailyNetStr = (
                                floatingProfitEOD + closedProfitEOD
                            ).toFixed(2);
                        }
                    });
                }
            }
            this.pkData = clone[this.selectedBook];

            for (const book in clone) {
                let mockedCPnl = 0;
                let mockedFPnl = 0;

                clone[book].map((obj) => {
                    const randomNumber =
                        Math.random() * (obj.floatingProfitEOD / 10) -
                        obj.floatingProfitEOD / 20;
                    const variation =
                        Math.abs(randomNumber) > obj.floatingProfitEOD / 100
                            ? randomNumber
                            : 0;
                    mockedCPnl += obj.closedProfitEOD + variation;
                    mockedFPnl += obj.floatingProfitEOD + variation;
                });

                this.calculateAllBooks({
                    book: book,
                    broker: "Demo",
                    closedProfits: mockedCPnl + mockedFPnl,
                    closedProfitsEOD: mockedCPnl,
                    floatingProfitsEOD: mockedFPnl,
                });
                if (book === this.selectedBook) {
                    this.UPDATE_MAIN_BOOK_TITLE({
                        unrealized: mockedFPnl,
                        realized: mockedCPnl,
                        dailyNet: mockedCPnl + mockedFPnl,
                    });
                }
            }

            if (!!this.currentExpanded) {
                const copy = deepClone(
                    this.mockedAllSymbolDetail[this.currentExpanded]
                );

                this.symbolDetailObject = copy;
            } else {
                this.symbolDetailObject = [];
            }
        },
        getTutorial() {
            const steps = [
                {
                    element: document.getElementById("monitorHomeTutorial"),
                    title: this.tutorialConstant["home-page-intro"][
                        "monitorHomeTutorial"
                    ]["title"],
                    intro: this.tutorialConstant["home-page-intro"][
                        "monitorHomeTutorial"
                    ]["intro"],
                },
                {
                    element: document.getElementById("allBookHomeTutorial"),
                    title: this.tutorialConstant["home-page-intro"][
                        "allBookHomeTutorial"
                    ]["title"],
                    intro: this.tutorialConstant["home-page-intro"][
                        "allBookHomeTutorial"
                    ]["intro"],
                },
                {
                    element: document.getElementById("exposureHomeTutorial"),
                    title: this.tutorialConstant["home-page-intro"][
                        "exposureHomeTutorial"
                    ]["title"],
                    intro: this.tutorialConstant["home-page-intro"][
                        "exposureHomeTutorial"
                    ]["intro"],
                },
                {
                    element: document.getElementById("profileHomeTutorial"),
                    title: this.tutorialConstant["home-page-intro"][
                        "profileHomeTutorial"
                    ]["title"],
                    intro: this.tutorialConstant["home-page-intro"][
                        "profileHomeTutorial"
                    ]["intro"],
                },
                {
                    element: document.getElementById("home-table"),
                    title: this.tutorialConstant["home-page-intro"][
                        "home-table"
                    ]["title"],
                    intro: this.tutorialConstant["home-page-intro"][
                        "home-table"
                    ]["intro"],
                },
                {
                    element: document.getElementById("symbolSearch"),
                    title: this.tutorialConstant["home-page-intro"][
                        "symbolSearch"
                    ]["title"],
                    intro: this.tutorialConstant["home-page-intro"][
                        "symbolSearch"
                    ]["intro"],
                },
                {
                    element: document.getElementById("symbolEODDialog"),
                    title: this.tutorialConstant["home-page-intro"][
                        "symbolEODDialog"
                    ]["title"],
                    intro: this.tutorialConstant["home-page-intro"][
                        "symbolEODDialog"
                    ]["intro"],
                },
                {
                    element: document.getElementById("monitorFunctions"),
                    title: this.tutorialConstant["home-page-intro"][
                        "monitorFunctions"
                    ]["title"],
                    intro: this.tutorialConstant["home-page-intro"][
                        "monitorFunctions"
                    ]["intro"],
                },
                {
                    element: document.getElementById("allBookFunction"),
                    title: this.tutorialConstant["home-page-intro"][
                        "allBookFunction"
                    ]["title"],
                    intro: this.tutorialConstant["home-page-intro"][
                        "allBookFunction"
                    ]["intro"],
                },
            ];
            console.log(localStorage.getItem("tutorial_presented"));
            if (
                !localStorage
                    .getItem("tutorial_presented")
                    .includes("home_page")
            ) {
                steps.unshift({
                    element: document.getElementById("homeTutorialIcon"),
                    title: this.tutorialConstant["home-page-intro"][
                        "homeTutorialIcon"
                    ]["title"],
                    intro: this.tutorialConstant["home-page-intro"][
                        "homeTutorialIcon"
                    ]["intro"],
                });
            }
            return introJs().setOptions({
                steps: steps,
            });
        },
        toggleSwitch() {
            if (!this.selectAll) {
                Object.keys(this.csvFields).map(
                    (item) => (this.selectedCSVFields[item] = false)
                );
            } else {
                Object.keys(this.csvFields).map(
                    (item) => (this.selectedCSVFields[item] = true)
                );
            }
        },
        checkAll() {
            let allSelected = true;
            Object.keys(this.selectedCSVFields).map((item) => {
                allSelected = allSelected && this.selectedCSVFields[item];
            });
            this.selectAll = allSelected;
        },
        async requestAllBookDownload(params) {
            try {
                let url =
                    document.querySelector("body").getAttribute("apiurl") ||
                    process.env.VUE_APP_ENDPOINT;
                url = url + "/api/v1/statistic/download-all-books";
                const response = await fetch(url, {
                    method: "POST",
                    body: JSON.stringify(params),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + getToken("token"),
                    },
                });

                // Check if the response is ok
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }

                return response; // This should be a Response object
            } catch (error) {
                console.error("Error in requestAllBookDownload:", error);
                throw error;
            }
        },
        downloadAllBooks() {
            this.allBookDownloadLoading = true;
            this.snackBarSuccess(
                "Request has been sent, download will be automatically generate once file is ready.",
                2000
            );
            const timeNow = dayjs()
                .tz("US/Eastern")
                .add(7, "hour")
                .add(-1, "minute")
                .format("YYYY_MM_DD HH_mm_00");

            const params = {
                request_time: timeNow,
            };
            this.requestAllBookDownload(params)
                .then(async (res) => {
                    if (res && typeof res.blob === "function") {
                        const blob = await res.blob();
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = "Book Summary - " + timeNow + ".xlsx";
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    } else {
                        throw new Error("The response is not a valid Blob");
                    }
                    this.allBookDownloadLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.allBookDownloadLoading = false;
                    this.snackBarDanger(
                        "Request failed, please try again later."
                    );
                });
        },
        startDownload() {
            this.selectAll = true;
            this.selectedCSVFields = [];
            this.isCsvRecording = true;
            Object.keys(this.csvFields).map(
                (item) => (this.selectedCSVFields[item] = true)
            );
            this.inputCsvName = "position " + this.selectedBook;
            this.csvFieldsDialog = true;
        },
        confirmDownload() {
            const copy = deepClone(this.csvFields);
            Object.keys(this.selectedCSVFields).map((item) => {
                if (!this.selectedCSVFields[item]) {
                    delete copy[item];
                }
            });
            this.computedCSVFields = copy;
            if (Object.keys(this.computedCSVFields).length === 0) {
                this.snackBarDanger("Please selected at least one field");
            } else {
                document.getElementById("csvExport").click();
                if (this.allowSymbolDetailsDownload && this.monitorDialog) {
                    var dataStr =
                        "data:text/json;charset=utf-8," +
                        encodeURIComponent(
                            JSON.stringify(this.symbolDetailArray)
                        );
                    var dlAnchorElem = document.getElementById(
                        "downloadAnchorElement"
                    );
                    dlAnchorElem.setAttribute("href", dataStr);
                    dlAnchorElem.setAttribute("download", "SymbolDetails.json");
                    dlAnchorElem.click();
                }
            }
        },
        cancelDownload() {
            this.csvFieldsDialog = false;
        },
        async fetchData() {
            this.csvFieldsDialog = false;
            return this.computedPKData;
        },
        sortPKHeadersAndUpdateTheKey(evt) {
            const headersTmp = this.mainHeader;
            const oldIndex = evt.oldIndex;
            const newIndex = evt.newIndex;
            if (newIndex >= headersTmp.length) {
                let k = newIndex - headersTmp.length + 1;
                while (k--) {
                    headersTmp.push(undefined);
                }
            }
            headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
            localStorage.setItem("mainHeader", JSON.stringify(headersTmp));
            this.anIncreasingNumberForMain += 1;
        },
        reconnect() {
            if (this.lockReconnect) return;
            this.lockReconnect = true;
            setTimeout(() => {
                if (this.pkSocket == null || this.pkSocket.readyState == 3) {
                    console.log("reconnecting...");
                    const lastSelection = this.selectedProfile;
                    this.UPDATE_SELECTED_PROFILE(null);
                    this.UPDATE_SELECTED_PROFILE(lastSelection);
                }
                this.lockReconnect = false;
            }, 1000);
        },
        setHeights() {
            if (!this.VUETIFY_BREAKPOINT.mobile) {
                const innerHeight = window.innerHeight;
                this.tableHeight =
                    innerHeight - 273 > 636 ? innerHeight - 273 : 636;
                // 929 -120 -10 *0.4
                this.allBookTableHeight =
                    (innerHeight - 130) * 0.6 - 37 > 400
                        ? (innerHeight - 130) * 0.6 - 37
                        : 400;
                this.exposureChartHeight =
                    innerHeight - 130 - this.allBookTableHeight - 37 - 39 > 200
                        ? innerHeight - 130 - this.allBookTableHeight - 37 - 39
                        : 200;
            }
        },
        onResize() {
            this.setHeights();
        },
        /**
         * Load month to date volume
         * @return  {[type]}  [return description]
         */
        loadMTDVolume() {
            const self = this;
            const params = { book_list: this.bookList };
            getMTDVolume(params).then((res) => {
                if (typeof res.data === "string") {
                    const output = res.data.replaceAll(NaN, null);
                    self.mtdVolume = JSON.parse(output);
                } else {
                    self.mtdVolume = res.data;
                }
            });
        },
        /**
         * Load month to date volume
         * @return  {[type]}  [return description]
         */
        loadMTDPnl() {
            const self = this;
            const params = { book_list: this.bookList };

            getMTDPnl(params).then((res) => {
                if (typeof res.data === "string") {
                    const output = res.data.replaceAll(NaN, null);
                    self.mtdPnl = JSON.parse(output);
                } else {
                    self.mtdPnl = res.data;
                }
            });
        },
        onMenuItemClick(item) {
            this.selectedBook = item;
        },
        rowClicked(data) {
            const symbolSnapshot = {
                long: parseFloat(data.longPositions),
                short: parseFloat(data.shortPositions),
                net: parseFloat(data.net),
                realized: parseFloat(data.closedProfitEOD),
                unrealized: parseFloat(data.floatingProfitEOD),
                dailyNet: parseFloat(data.dailyNet),
            };
            this.UPDATE_SYMBOL_SNAPSHOT(symbolSnapshot);
            if (this.monitorDialog === false) {
                if (this.currentExpanded === data.symbol) {
                    this.monitorDialog = true;
                } else {
                    this.currentExpanded = data.symbol;
                    this.symbolDetailArray = [];
                    this.$nextTick(() => {
                        this.sendExpandSymbolRequest();
                    });
                }
            } else {
                if (this.currentExpanded !== data.symbol) {
                    this.currentExpanded = data.symbol;
                    this.symbolDetailArray = [];
                    this.$nextTick(() => {
                        this.sendExpandSymbolRequest();
                    });
                }
            }
        },
        sendExpandSymbolRequest() {
            this.monitorDialog = true;
            this.monitorDialogLoading = true;
            const pullCommand = {
                externalMsgType: "CMD_PULL_BOOK_SYMBOL_RESULT",
                symbol: this.currentExpanded,
                book: this.selectedBook,
                ref: this.selectedBook + "|" + this.currentExpanded,
            };
            this.fetchSymbolDetail(pullCommand);
        },
        changeSelectedBook(book) {
            this.selectedBook = book;
        },
        filterTable(value, search, item) {
            return (
                value !== null &&
                search !== null &&
                typeof value === "string" &&
                value.toString().toLowerCase().indexOf(search.toLowerCase()) !==
                    -1
            );
        },
        customSort(items, sortBy, sortDesc) {
            items.sort((a, b) => {
                if (sortBy[0] === "net") {
                    if (!sortDesc[0]) {
                        return (
                            Math.abs(
                                parseFloat(a.longPositionsStr) +
                                    parseFloat(a.shortPositionsStr)
                            ) -
                            Math.abs(
                                parseFloat(b.longPositionsStr) +
                                    parseFloat(b.shortPositionsStr)
                            )
                        );
                    }
                    if (sortDesc[0]) {
                        return (
                            Math.abs(
                                parseFloat(b.longPositionsStr) +
                                    parseFloat(b.shortPositionsStr)
                            ) -
                            Math.abs(
                                parseFloat(a.longPositionsStr) +
                                    parseFloat(a.shortPositionsStr)
                            )
                        );
                    }
                } else if (sortBy[0] === "dailyNet") {
                    if (!sortDesc[0]) {
                        return (
                            parseFloat(a.floatingProfitEODStr) +
                            parseFloat(a.closedProfitEODStr) -
                            (parseFloat(b.floatingProfitEODStr) +
                                parseFloat(b.closedProfitEODStr))
                        );
                    }
                    if (sortDesc[0]) {
                        return (
                            parseFloat(b.floatingProfitEODStr) +
                            parseFloat(b.closedProfitEODStr) -
                            (parseFloat(a.floatingProfitEODStr) +
                                parseFloat(a.closedProfitEODStr))
                        );
                    }
                } else if (sortBy[0] === "symbol") {
                    if (!sortDesc[0]) return a.symbol.localeCompare(b.symbol);
                    if (sortDesc[0]) return b.symbol.localeCompare(a.symbol);
                } else if (sortBy[0] === "symbolExposure") {
                    if (!sortDesc[0]) {
                        return Math.abs(a[sortBy[0]]) - Math.abs(b[sortBy[0]]);
                    }
                    if (sortDesc[0]) {
                        return Math.abs(b[sortBy[0]]) - Math.abs(a[sortBy[0]]);
                    }
                } else if (sortBy[0] === "closedProfitEOD") {
                    if (!sortDesc[0]) {
                        return (
                            Math.abs(a[sortBy[0] + "Str"]) -
                            Math.abs(b[sortBy[0] + "Str"])
                        );
                    }
                    if (sortDesc[0]) {
                        return (
                            Math.abs(b[sortBy[0] + "Str"]) -
                            Math.abs(a[sortBy[0] + "Str"])
                        );
                    }
                } else {
                    if (!sortDesc[0]) {
                        return parseFloat(a[sortBy + "Str"]) <
                            parseFloat(b[sortBy + "Str"])
                            ? -1
                            : 1;
                    } else {
                        return parseFloat(b[sortBy + "Str"]) <
                            parseFloat(a[sortBy + "Str"])
                            ? -1
                            : 1;
                    }
                }
            });
            return items;
        },
        changeTab(key) {
            this.selectedKey = key;
            if (key !== "raw") {
                this.dialogWidth = "500px";
            } else {
                this.dialogWidth = "1200px";
            }

            const params = {
                symbol: this.closeSummarySymbol,
                book: this.selectedBook,
                date: common.getMT4TimeString().split(" ")[0] + " 00:00:00",
                key: this.selectedKey,
            };
            this.getPNLSummaryAction(params);
        },
        /**
         * Load user history chart
         * @param   {[type]}  item  [item description]
         * @return  {[type]}        [return description]
         */
        loadLoginSummaryHistoryDialog(item) {
            const symbolSnapshot = {
                long: parseFloat(item.longPositions),
                short: parseFloat(item.shortPositions),
                net: parseFloat(item.netPositions),
                realized: parseFloat(item.closedProfitEOD),
                unrealized: parseFloat(item.floatingProfitEOD),
                dailyNet: parseFloat(item.dailyNet),
            };
            this.UPDATE_LOGIN_SNAPSHOT(symbolSnapshot);
            if (!this.showHomeLoginDetailSummary) {
                return;
            }
            // split login string to get login value
            const [server, login] = item.login.split(":");
            this.fullLoginValue = item.fullLogin;
            this.loginValue = login;
            this.serverValue = server;
            this.symbolValue = this.currentExpanded;

            this.loginSummaryHistoryVisible = false;
            this.$nextTick(() => {
                this.loginSummaryHistoryVisible = true;
            });
        },
        /**
         * Send data to socket once it is open
         * @param   {[type]}  evt  [item description]
         * @return  {[type]}        [return description]
         */
        onWebSocketOpen(evt) {
            const data = {
                token: localStorage.getItem("token"),
                externalMsgType: "CMD_AUTHORIZATION",
            };
            this.pkSocket.send(JSON.stringify(data));
            // resend expanded request
            if (this.currentExpanded) {
                this.sendExpandSymbolRequest();
            }
        },
        fetchSymbolDetail(request) {
            this.symbolDetailArray = mockedData.mockedTestData;
        },
        onWindowFocus() {
            if (this.pkSocket == null || this.pkSocket.readyState == 3) {
                const lastSelection = this.selectedProfile;
                this.UPDATE_SELECTED_PROFILE(null);
                this.UPDATE_SELECTED_PROFILE(lastSelection);
            }
        },
        initConnection() {
            this.snackBarInfo("Connecting to socket...", 10000);
            this.selectedBook = null;
            this.isDataStreaming = false;
        },
        // call this function to auto focus onto the search input field
        onSearch(field) {
            setTimeout(() => {
                this.$refs[field].focus();
            }, 200);
        },
        getPNLSummary(data) {
            this.dialogCloseSummaryVisible = true;
            this.closeSummarySymbol = data.symbol;
            const params = {
                symbol: this.closeSummarySymbol,
                book: this.selectedBook,
                date: common.getMT4TimeString().split(" ")[0] + " 00:00:00",
                key: this.selectedKey,
            };
            this.getPNLSummaryAction(params);
        },
        getEODSymbolMaps(data) {
            this.symbolMap = true;
            this.selectedSymbolMap = data;
        },
        toMTTimeString(timestamp) {
            const utc = require("dayjs/plugin/utc");
            const timezone = require("dayjs/plugin/timezone");
            dayjs.extend(utc);
            dayjs.extend(timezone);
            const date = dayjs
                .unix(timestamp)
                .tz("UTC")
                .format("YYYY-MM-DD HH:mm:ss");
            return date;
        },
    },
    created() {
        const userFunction = this.getFunction("book");

        if (userFunction.includes("homeLoginDetailSummary")) {
            this.showHomeLoginDetailSummary = true;
        }
        if (userFunction.includes("extraSymbolDetails")) {
            this.allowSymbolDetailsDownload = true;
        }
        if (userFunction.includes("allowDownloadAll")) {
            this.allowDownloadAll = true;
        }

        document.addEventListener("visibilitychange", this.userFocus);

        var localSavedMainHeader = localStorage.getItem("mainHeader");

        if (localSavedMainHeader) {
            this.mainHeader = JSON.parse(localSavedMainHeader);
        } else {
            this.mainHeader = deepClone(this.pkHeaders);
        }

        // call getBooks actions to load books
        this.getBooksPkAction();
        this.getServerAction();
    },
    destroyed() {
        if (this.pkSocket !== null) {
            this.pkSocket.close();
        }

        // window.removeEventListener("focus", this.onWindowFocus);
        clearInterval(this.allBookHBInterval);
        clearInterval(this.detailHBInterval);
        clearInterval(this.mockedAllBookInterval);
        clearInterval(this.chartInterval);
        clearInterval(this.mockedHeaderTileInterval);
        clearInterval(this.mockedBookDetailInterval);
        clearInterval(this.mockedSymbolDetailInterval);
    },
};
</script>

<style lang="scss" scoped>
.green {
    background-color: #27ae60;
}

.red {
    background-color: #c0392b;
}

.td-alert {
    background-color: #c0392b;
}
.hover-login-tooltip:hover {
    cursor: pointer;
}
.symbol-detail:hover {
    cursor: pointer;
}

#home-table table > tbody {
    /* height: 600px; */
    overflow-y: scroll;
}
#home-table
    > div.v-data-table__wrapper
    > table
    > tbody
    > tr.v-data-table__expanded.v-data-table__expanded__content
    > td
    > div.v-data-table.v-data-table--dense.v-data-table--fixed-header.theme--dark
    > div
    > table
    > tbody
    > tr:nth-child(n) {
    height: 32px;
}
#home-table
    > div.v-data-table__wrapper
    > table
    > tbody
    > tr.v-data-table__expanded.v-data-table__expanded__content
    > td
    > div.v-data-table.v-data-table--dense.v-data-table--fixed-header.theme--dark
    > div
    > table
    > tbody {
    height: 32px;
}
.home-checkbox .v-label {
    font-size: 12px;
}
.home-checkbox .v-messages {
    display: none;
}
#global-container
    > div.home
    > div
    > div
    > div:nth-child(5)
    > div:nth-child(2)
    > div.col-md-5.col-lg-5.col-12
    > div:nth-child(2)
    > div:nth-child(n)
    > button:hover {
    background-color: #2196f3;
}
#home-table > div.v-data-table__wrapper > table > tbody > tr:nth-child(n) {
    height: 19px;
}
#home-table
    > div.v-data-table__wrapper
    > table
    > tbody
    > tr.v-data-table__expanded.v-data-table__expanded__row:nth-child(n) {
    height: 32px;
}

#home-table table > tbody::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

#home-table table > tbody::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

#home-table table > tbody::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    background-color: #555;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
}

.v-btn:not(.v-btn--round).v-size--small {
    height: 19px;
}
.v-tab {
    padding: 0 7px;
}
</style>

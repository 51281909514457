
export function getMTDPnl(query) {
    const mockData = {
        status: 200,
        data: [
            {
                "book": "B",
                "mtd_pnl": 13010.902822659948
            },
            {
                "book": "S",
                "mtd_pnl": 23.179667666958025
            },
            {
                "book": "B2",
                "mtd_pnl": 1321.54
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
